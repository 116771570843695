.EstateWhishlist {
  display: flex;
  justify-content: center;
  background-color: var(--color-tertiary-04);
  padding: 32px; }
.EstateWhishlist-Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px; }

.EstateWhishlist-Header {
  display: flex;
  justify-content: space-between;
  align-items: center; }
