.EstateParamsData {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  .Title {
    grid-column: 1/-1; }
  .ParamData {
    align-items: flex-start;
    break-inside: avoid;
    display: flex;
    justify-content: space-between;
    page-break-inside: avoid; }
  .ParamTitle {
    align-items: flex-start;
    flex-shrink: 0; }
  .ParamDevider {
    border-bottom: 1px dotted #d3d4d4;
    content: "";
    flex: 1 1 12px;
    height: 11px;
    margin: 5px 6px 0 2px; }
  .ParamValue {
    display: inline-block;
    flex-shrink: 1;
    max-width: 50%;
    padding-left: 8px;
    text-align: right; } }
