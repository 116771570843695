.RieltorTile {
  display: grid;
  grid-template-columns: 48px 1fr;
  grid-gap: 8px;
  .Avatar {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 100%;
    img {
      width: 48px;
      height: 48px;
      border-radius: 100%;
      object-fit: cover;
      object-position: top center; } }
  .Data {
    .UserName {
      font-size: 14px;
      font-weight: 600; }
    .Rating {
      display: grid;
      grid-template-columns: repeat(3, max-content);
      grid-gap: 8px;
      align-items: center;
      .Icon {
        color: #F9C615;
        font-size: 14px; }
      .FeedsCounter {
        color: var(--color-tertiary-6); } } } }
