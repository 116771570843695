.Edu-CourseInfo {
  display: flex;
  justify-content: center;
  .CourseInfo-Description {
    color: var(--color-tertiary-4);
    margin-top: 12px; }

  .Course-InfoBenefits {
    margin-top: 48px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 24px;
    margin-bottom: 48px; }
  .InfoBenefit {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    padding: 24px;
    grid-column: span 2;
    .Title {
      display: flex;
      align-items: center;
      h2 {
        margin-left: 12px; } }
    &:nth-last-child(2), &:nth-last-child(1) {
      grid-column: span 3; } }

  .Course-Program {
    margin-top: 48px;
    display: grid;
    grid-template-columns: 440px 1fr;
    grid-gap: 24px;
    align-items: start;
    .Program-Image {
      width: 440px;
      img {
        width: 440px; } } }
  .ProgramTile {
    padding: 16px;
    border-top: 1px var(--color-tertiary-4) solid;
    .ProgramTile-Header {
      display: flex;
      align-items: center;
      cursor: pointer; }
    &:first-child {
      border-top: 0; }
    .ProgramTile-Body {
      padding-left: 80px;
      margin-top: 12px;
      display: none;
      .Content {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 12px; } }

    .ProgramTile-Check {
      width: 45px;
      flex-shrink: 0;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-primary-2);
      border-radius: 4px;
      color: var(--color-primary-8);
      margin-right: 16px;
      .Icon {
        font-size: 20px; } } }
  .Spoiler_status_expanded {
    .Icon {
      transform: rotate(90deg); }
    .ProgramTile-Body {
      display: block; } } }

@media screen and ( max-width: 576px ) {
  .Edu-CourseInfo {
    .Course-InfoBenefits {
      grid-template-columns: 1fr; }
    .InfoBenefit {
      grid-column: 1/-1 !important; }
    .Course-Program {
      grid-template-columns: 1fr;
      .Program-Image {
        width: 100%;
        img {
          width: 100%; } } } } }
