.Services {
  display: flex;
  justify-content: center;
  .Services-List {
    margin-top: 32px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 24px; }
  .ServiceTile {
    padding: 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    .Title {
      text-transform: uppercase;
      margin-bottom: 16px; }
    .ServiceTile-IconContainer {
      width: 80px;
      height: 80px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 40px;
      margin-bottom: -24px;
      margin-left: auto;
      margin-right: -24px;
      border-bottom-right-radius: 8px;
      .ServiceIcon {
        width: 64px;
        height: 64px;
        object-fit: cover;
        object-position: center center; } }
    &:hover {
      box-shadow: var(--shadow-m); } } }

@media screen and ( max-width: 576px ) {
  .Services {
    .Services-List {
      grid-template-columns: 1fr; } } }
