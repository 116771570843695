.Internship {
  display: flex;
  justify-content: center;
  background: var(--color-tertiary-04);
  padding: 48px 0px; }
.Internship-Container {}
.Intership-Body {
  margin-top: 48px;
  display: grid;
  grid-template-columns: 1fr 540px;
  grid-gap: 64px;
  .Text {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px; }
  .Ticks {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 24px;
    .Icon {
      color: var(--color-primary-8); } }
  .Image {
    width: 540px;
    height: 100%;
    object-fit: cover;
    border-radius: 8px; } }

@media screen and ( max-width: 576px ) {
  .Intership-Body {
    grid-template-columns: 1fr;
    grid-gap: 48px;
    .Image {
      width: 100%;
      order: 0; }
    .Text {
      order: 1; }
    .Ticks {
      grid-template-columns: 1fr; } } }
