.Resume {
  display: flex;
  justify-content: center; }
.Resume-Container {
  display: grid;
  grid-template-columns: 1fr; }
.Resume-Employee {
  display: flex;
  align-items: center;
  img {
    height: 500px; } }
.Resume-Bar {
  padding: 32px;
  display: flex;
  justify-content: center;
  border: 1px var(--color-tertiary-2) solid;
  border-radius: 8px; }

.ResumeTile {
  display: flex;
  margin-right: 48px;
  padding-right: 48px;
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 2px;
    height: 24px;
    background-color: var(--color-primary-8);
    top: 50%;
    margin-top: -12px;
    right: 0;
    margin-left: -48px; }
  &:last-child {
    &::after {
      display: none; } }
  .Icon {
    margin-right: 24px;
    font-size: 36px;
    color: var(--color-primary-8); } }
.Resume-Skills {
  background: linear-gradient(90deg, #078E3B 0%, #7ED136 100%), #FFFFFF;
  padding: 32px;
  color: #fff;
  border-radius: 8px;
  margin-top: 24px;
  position: relative;
  .Decoration {
    width: 400px;
    height: 200px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0; }
  .Skills-Container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 64px;
    margin-top: 24px;
    position: relative;
    z-index: 1;
    ul {
      list-style-type: disc;
      font-size: 18px;
      font-weight: 500;
      padding-left: 16px;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 16px; } } }

@media screen and ( max-width: 576px) {
  .Resume-Employee {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 0px;
      width: 100%;
      height: auto; } }
  .Resume-Bar {
    padding: 24px;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .ResumeTile {
    margin-bottom: 48px;
    margin-right: 0;
    padding-right: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:last-child {
      margin-bottom: 0; }
    &::after {
      display: none; }
    .Icon {
      margin-right: 0px;
      margin-bottom: 16px; }
    .Content {
      text-align: center; } }
  .Resume-Skills {
    padding: 24px 16px;
    .Skills-Container {
      grid-template-columns: 1fr;
      grid-gap: 16px; } } }
