.About {
  display: flex;
  justify-content: center;
  .About-Body {
    margin-top: 48px;
    display: grid;
    grid-template-columns: 540px 1fr;
    grid-gap: 64px;
    align-items: start;
    .Image {
      width: 100%;
      img {
        width: 100%;
        object-fit: cover; } } }
  .Text {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px; } }

@media screen and ( max-width: 576px ) {
  .About {
    .About-Body {
      grid-template-columns: 1fr;
      grid-gap: 48px; } } }
