.EduCheckout {
  padding: 64px 0px;
  width: 100%;
  background: linear-gradient(90deg, #7ED136 0%, #078E3B 100%);
  display: flex;
  justify-content: center;
  position: relative;
  .Pattern {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: bottom right;
    z-index: 0; }
  .PopUp-List {
    margin-top: 12px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    list-style-type: disc;
    padding-left: 16px;
    margin-bottom: 16px; }
  .EduForm {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    justify-content: center; } }
.EduCheckout-Container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #fff;
  position: relative;
  z-index: 1;
  .Description {
    margin: 12px 0px; }
  .Button {
    background-color: var(--color-tertiary-10); } }

@media screen and ( max-width: 576px ) {
  .EduCheckout-Container {
    text-align: center; } }
