.News {
  display: flex;
  justify-content: center;
  padding: 48px 0px;
  background-color: var(--color-tertiary-04); }
.News-Container {
  display: grid;
  grid-template-columns: 1fr 250px 250px;
  grid-gap: 24px;
  margin-top: 24px;
  .ModuleSpinner-Container {
    grid-column: 1/-2; }
  .TopNews {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 24px;
    align-content: start; }
  .BigTopType {
    grid-column: 1/-1; }
  .TopType {
    .NewsTile-Image {
      height: 200px; } }
  .OtherNews {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    align-content: start;
    .DefaultType {
      .Title {
        font-size: 14px; }
      .NewsTile-Content {
        margin-top: 0; }
      .NewsTile-Image {
        display: none; }
      &:not(:last-child) {
        border-bottom: 1px var(--color-tertiary-1) solid;
        padding-bottom: 12px; } } }
  .AskBlock {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    align-content: start;
    .AskImg {
      width: 100%;
      height: 300px;
      object-fit: cover;
      object-position: top center; }
    .Button {
      width: 100%; } } }
.NewsTile {
  width: 100%;
  cursor: pointer;

  .NewsTile-Content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    margin-top: 16px;
    color: var(--color-tertiary-8);
    .Date {
      color: var(--color-tertiary-4); } }
  .NewsTile-Image {
    width: 100%;
    height: 320px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center; } } }

@media screen and ( max-width: 576px ) {
  .News-Container {
    grid-template-columns: 1fr; } }
