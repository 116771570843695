.Stepper {
  display: flex;
  justify-content: center; }
.Stepper-Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  .Stepper-TilesContainer {
    margin-top: 64px;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr)); }
  .StepTile {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    align-content: start;
    position: relative;
    .StepLine {
      width: 100%;
      height: 1px;
      background: var(--color-tertiary-2);
      content: '';
      display: block;
      position: absolute;
      top: 20px;
      z-index: 1; }
    .StepIndex {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      background-color: var(--color-primary-8);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      position: relative;
      z-index: 2; }
    &:last-child {
      .StepLine {
        display: none; } } } }

@media screen and ( max-width: 576px ) {
  .Stepper-Container {
    .Stepper-TilesContainer {
      margin-top: 48px;
      grid-template-columns: 1fr;
      grid-gap: 24px; }
    .StepTile {
      grid-template-columns: max-content 1fr;
      align-items: center;

      .StepLine {
        display: none; } } } }
