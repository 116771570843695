.EstateOffer {
  display: flex;
  justify-content: center;
  .EstateOffer-Container {
    display: grid;
    grid-template-columns: 1fr 250px;
    grid-gap: 24px; }
  .EstateBlock-Container {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 24px;
    margin-top: 24px; }
  .RieltorsBlock-Container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin-top: 24px;
    .RieltorTile {
      padding-bottom: 12px;
      &:not(:last-child) {
        border-bottom: 1px var(--color-tertiary-1) solid; } } } }

@media screen and ( max-width: 576px) {
  .EstateOffer {
    .EstateOffer-Container, .EstateBlock-Container {
      grid-template-columns: 1fr; }
    .RieltorsBlock {
      display: none; } } }
