.NovostroyTile {
  background-color: #fff;
  cursor: pointer;
  color: #000;
  border-radius: 4px;
  .Image {
    width: 100%;
    height: 290px;
    background-size: cover;
    background-position: center center;
    border-radius: 4px; }
  &:hover {
    box-shadow: var(--shadow-l); }
  .Content {
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px; }
  .EstateTile-Address {
    display: flex;
    al {}
    .Icon {
      margin-right: 8px; } }
  .EstateParamsData {
    margin: 16px 0px;
    grid-template-columns: 1fr; }
  .StartFromPrice {
    margin-top: auto; } }
