.Partners {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 0px;
  flex-direction: column;
  .Partners-Container {
    margin-top: 48px;
    h2 {
      margin-bottom: 48px; } }
  .PartnerTile {
    width: 100%;
    height: 100px;
    padding: 8px;
    background-color: #fff;
    border: 1px var(--color-tertiary-2) solid;
    display: block;
    span {
      width: 100%;
      height: 100%; }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center; } } }

@media screen and ( max-width:  576px) {
  .Partners {
    .Partners-Container {
      width: 375px; } } }
