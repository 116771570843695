.ToRentRequest {
  display: flex;
  justify-content: center; }
.ToRentRequest-Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px; }

.ToRent-Offer {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  .InfoLine {
    .Icon {
      color: var(--color-secondary-6); } } }

.ToRentRequest-FormContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 24px;
  .TextForm {
    width: 100%;
    border-radius: 8px;
    padding: 24px;
    border: 1px var(--color-tertiary-2) solid;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    justify-items: start;
    .EstateTiles {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 24px; }
    .EstateTile {
      padding: 12px;
      border-radius: 8px;
      border: 1px var(--color-tertiary-2) solid;
      display: grid;
      grid-template-columns: 52px 1fr;
      grid-gap: 16px;
      align-items: center;
      .Title {
        color: var(--color-tertiary-8);
        font-size: 16px;
        font-weight: 700; } } } }

@media screen and ( max-width: 576px ) {
  .ToRentRequest-FormContainer {
    grid-template-columns: 1fr; } }
