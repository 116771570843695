.ImageCarousel {
  img {
    border-radius: 4px; }
  .Thumbnails {
    margin-top: 12px;
    display: grid;
    grid-gap: 12px;
    border-radius: 4px;
    img {
      width: 100%;
      object-fit: cover;
      object-position: top center; }
    .LastPhoto {
      position: relative;
      border-radius: 4px;
      .TotalHiddenPhotos {
        border-radius: 4px;
        position: absolute;
        background-color: rgba(0,0,0,0.4);
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center; } } } }
