.LocationNotificationPopUp {
  min-width: 600px;
  .PopUp-Header {
    .Icon {
      color: var(--color-functional-error-small); } } }

.LocationNotificationPopUp-Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  .Buttons {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px; } }
