.PopUp {
  max-width: 500px;
  width: 100%;
  background-color: #fff;
  padding: 32px;
  position: absolute;
  top: 62px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  border-radius: 8px;
  .Heder-Title {
    display: flex;
    align-items: center;
    .Icon {
      margin-right: 16px; } }
  .PopUp-Header {
    color: var(--color-tertiary-10);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .Icon {
      font-size: 24px;
      color: var(--color-tertiary-6);
      cursor: pointer; } }
  .PopUp-Content {
    margin-top: 24px; } }

@media screen and ( max-width: 1366px ) {
  .PopUp {
    position: fixed;
    min-width: 100% !important;
    width: 100vw !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
    height: 100vh;
    transform: none;
    overflow: scroll !important; } }
