.NewsPage {
  display: flex;
  justify-content: center;
  padding: 48px 0px;
  background-color: #f1f7f9; }
.NewsPage-Container {
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 32px;
  .Date {
    color: var(--color-tertiary-4); }
  .Title {
    font-size: 24px;
    font-weight: 700;
    margin: 12px 0px 24px 0px; }
  .NewsPage-Avatar {
    width: 100%;
    height: 400px;
    object-fit: contain;
    margin-bottom: 32px;
    background-color: var(--color-tertiary-1); } }
