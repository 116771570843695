.Nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  box-shadow: var(--shadow-l);
  background-color: #fff;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  transition: all 0.4s ease; }
.Nav_theme_shadow {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.07); }
.Nav-Container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  span {
    color: var(--color-tertiary-6); } }
.Nav-Menu {
  display: grid;
  grid-template-columns: repeat(6, max-content);
  grid-gap: 32px;
  align-items: center;
  .Site-NavLink {
    cursor: pointer !important;
    color: var(--color-tertiary-6);
    &:hover {
      color: var(--color-tertiary-10); } } }

@media screen and ( max-width: 576px ) {
  .Nav-Container {
    align-items: center;
    position: relative; }
  .Nav-Menu {
    display: none;
    position: absolute;
    top: 50px;
    grid-template-columns: 1fr;
    width: 100%;
    background-color: #fff;
    left: 0;
    padding: 16px; }
  .PseudoBlock {
    width: 24px; } }
