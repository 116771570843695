.Vikup {
  background-color: #fff; }
.VikupFeeds {
  background-color: var(--color-tertiary-04);
  padding: 64px 0px;
  margin-top: -64px; }

.Vikup-Header {
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
  background-color: var(--color-tertiary-04); }
.Vikup-Offer-Title {
  max-width: 630px;
  color: var(--color-tertiary-8) !important; }
.Vikup-Offer-Description {
  color: var(--color-tertiary-6) !important; }

@media screen and ( max-width: 576px) {
  .Vikup-Header {
    background-size: cover;
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.5);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0; }
    .Header-Offer {
      position: relative;
      z-index: 1; } }
  .Vikup-Offer-Title, .Vikup-Offer-Description {
    color: #fff !important; } }
