.NovostroyBenefits {
  display: flex;
  justify-content: center; }
.NovostroyBenefits-Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  .Benefits-TilesContainer {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 24px; }
  .BenefitTile {
    padding: 16px;
    border: 1px var(--color-tertiary-2) solid;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    text-align: center;
    margin-top: 66px;
    .BenefitIcon {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      background-color: var(--color-tertiary-04);
      justify-self: center;
      margin-top: -66px;
      display: flex;
      align-items: center;
      justify-content: center; } } }

@media screen and ( max-width: 576px ) {
  .NovostroyBenefits-Container {
    .Benefits-TilesContainer {
      grid-template-columns: 1fr !important; } } }
