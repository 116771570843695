.EstateObject {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  justify-items: center;
  background-color: var(--color-tertiary-04);
  padding-bottom: 32px;
  .EstateCarousel {
    background-color: var(--color-tertiary-04); } }
.EstateObject-Container {
  margin-top: 48px;
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-gap: 24px;
  align-items: start;
  .Map {
    width: 100%;
    height: 400px; }
  .EstateData {
    .ImageCarousel-Container {
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: #E2E6EB; }
    .EstateBaseCarousel {
      width: 500px;
      height: 300px;
      img {
        width: 100%;
        object-fit: contain;
        object-position: top center;
        height: 300px; } }
    .Thumbnails {
      display: grid;
      img {
        width: 100%;
        height: 70px; } }

    .EstateData-Container {
      padding: 24px;
      width: 100%;
      background-color: #fff;
      border-radius: 8px;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 24px; } }
  .ActionBlock {
    width: 100%;
    padding: 24px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: var(--shadow-m);
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    .Button {
      width: 100%; }
    .RequestPrice {
      margin-top: -12px; } } }

@media screen and ( max-width: 576px) {
  .EstateObject-Container {
    grid-template-columns: 1fr;
    .EstateData {
      .EstateBaseCarousel {
        width: 300px;
        height: 250px;
        img {
          height: 250px; } }
      .EstateParamsData {
        grid-template-columns: 1fr; } } } }
