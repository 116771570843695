.ToRent {}
// .VikupFeeds
//   background-color: var(--color-tertiary-04)
//   padding: 64px 0px
//   margin-top: -64px

.ToRent-Offer-Title {
  max-width: 630px;
  color: #fff; }
.ToRent-Offer-Description {
  color: #fff; }
