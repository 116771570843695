.Calc {
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background-position: top right, bottom left;
  background-repeat: no-repeat, no-repeat;
  background-color: var(--color-primary-8);
  color: #fff;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  .Form {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 24px;
    .Buttons {
      display: flex;
      justify-content: center; }
    .Checkbox-Container, .Description, .Buttons, .StatusMessage {
      grid-column: 1/-1; } } }

@media screen and ( max-width: 575px ) {
  .Calc {
    .Form {
      grid-template-columns: 1fr; } } }
