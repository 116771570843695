.NovostroyCalc {
  display: flex;
  justify-content: center; }
.NovostroyCalc-Calc {
  margin-top: 48px;
  padding: 24px;
  width: 100%;
  border-radius: 16px;
  border: 1px var(--color-tertiary-2) solid;
  .SelectionData {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 32px;
    align-items: start;
    .Title {
      font-weight: 700 !important; } }
  .TwoCols {
    grid-template-columns: repeat(2, max-content);
    display: grid;
    grid-gap: 24px;
    align-items: start;
    .Title {
      grid-column: 1/-1; } }
  .OneCol {
    grid-template-columns: max-content;
    display: grid;
    grid-gap: 24px; }
  .ThreeCols {
    grid-template-columns: repeat(3, max-content);
    display: grid;
    grid-gap: 24px;
    align-items: start;
    .Title {
      grid-column: 1/-1; } }
  .CalcDesign {
    margin-top: 24px;
    width: 100%;
    padding: 24px;
    border-radius: 8px;
    background-position: top right, bottom left;
    background-repeat: no-repeat, no-repeat;
    background-color: var(--color-primary-8);
    color: #fff;
    .Description {
      margin-top: 12px;
      margin-bottom: 24px; }
    .MessangersBlock {
      display: grid;
      grid-template-columns: repeat(4, max-content);
      grid-gap: 24px;
      margin-bottom: 24px;
      .Checkbox_theme_off {
        border: 2px #fff solid;
        background-color: #fff; } }
    .PhoneAndButton {
      display: grid;
      grid-template-columns: 500px max-content;
      align-items: end;
      grid-gap: 24px; }
    .Site-StatusMessage {
      margin-bottom: 24px; } } }

@media screen and ( max-width: 576px ) {
  .NovostroyCalc-Calc {
    .SelectionData {
      grid-template-columns: 1fr; }
    .ThreeCols {
      grid-template-columns: 1fr; }
    .CalcDesign {
      .MessangersBlock, .PhoneAndButton {
        grid-template-columns: 1fr; } } } }
