.RequestConsultation {
  background: linear-gradient(90deg, #7ED136 0%, #078E3B 100%), #F1F4F7;
  h1, p {
    color: #fff !important; }
  .Pattern {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    justify-items: center;
    background-size: cover;
    background-position: bottom center;
    height: 100%; }
  a {
    color: #fff;
    text-decoration: underline !important;
    &:hover {
      color: #fff;
      text-decoration: none !important; } } }
.RequestConsultation-Form {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 24px; }

@media screen and ( max-width: 576px ) {
  .RequestConsultation {
    padding: 48px 16px !important;
    h1, p {
      text-align: center; } }
  .RequestConsultation-Form {
    grid-template-columns: 1fr; } }
