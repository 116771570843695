.Novostroy {
  .SeoText {
    img {
      height: 100%; } }
  .Ticks {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    font-size: 18px;
    font-weight: 600;
    .Icon {
      color: var(--color-primary-8); } } }
.Novostroy-Header {
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
  background-color: var(--color-tertiary-04); }
.Novostroy-Offer-Title {
  max-width: 630px;
  color: var(--color-tertiary-8) !important; }
.Novostroy-Offer-Description {
  color: var(--color-tertiary-6) !important; }
.EstateCarousel-Tiles {
  margin-top: 24px; }

@media screen and ( max-width: 576px) {
  .Novostroy-Header {
    background-size: cover;
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.5);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0; }
    .Header-Offer {
      position: relative;
      z-index: 1; } }
  .Novostroy-Offer-Title, .Novostroy-Offer-Description {
    color: #fff !important; } }
