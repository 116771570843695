.EstateBase {
  display: flex;
  justify-content: center;
  background-color: var(--color-tertiary-04);
  padding: 32px; }
.EstateBase-Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px; }
.NovostroyBanner {
  width: 100%;
  border-radius: 16px;
  border: 1px var(--color-tertiary-2) solid;
  padding: 24px;
  height: 300px;
  display: flex;
  align-items: center;
  margin-bottom: 64px;
  background-position: top 0 right 0;
  background-repeat: no-repeat;
  .Content {
    max-width: 500px; }
  .Title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 12px; }
  .Description {
    font-size: 18px;
    margin-bottom: 24px; } }

.EstateBase-Header {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.EstateBaseTile {
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-gap: 24px;

  .DataBlock, .ParamBlock, .DescriptionBlock {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    color: #000; }
  .Carousel {
    width: 350px;
    height: 300px;
    img {
      width: 100%;
      object-fit: cover;
      object-position: top center;
      height: 300px; } }
  .ImageCarousel {
    .Thumbnails {
      display: grid;
      img {
        width: 100%;
        height: 70px; } } }

  .BaseTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .Controlls {
      display: flex;
      align-items: center;
      .Price {
        margin-right: 24px; } } }
  .ActionBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px; } }

@media screen and ( max-width: 576px ) {
  .EstateBase {
    padding: 32px 0px !important; }
  .NovostroyBanner {
    background-size: cover;
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.4);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      border-radius: 16px; }
    .Content {
      position: relative;
      z-index: 1;
      color: #fff; } }
  .EstateBase-Header {
    flex-direction: column;
    justify-self: start;
    align-items: start; }
  .EstateBaseTile {
    grid-template-columns: 1fr;
    .DataBlock {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 12px; }
    .BaseTitle {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 12px;
      .Controlls {
        justify-content: space-between; } }
    .EstateParamsData {
      grid-template-columns: 1fr; }
    .ActionBlock {
      flex-direction: column;
      align-items: start;
      width: 100%;
      a {
        margin-top: 24px;
        width: 100%;
        .Button {
          width: 100%; } } } } }
