.Home-Header {
  .Header-Offer {
    width: 600px; }
  .Offer-Title, .Offer-Description {
    color: #fff; } }
.HomeFeeds {
  background-color: var(--color-tertiary-04);
  padding: 48px; }

@media screen and ( max-width: 576px) {
  .Home-Header {
    .Header-Offer {
      width: 100%; } }
  .HomeFeeds {
    padding: 32px 0px; } }
