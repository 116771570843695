.Footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  hr {
    width: 100%;
    border-top: 1px solid var(--color-tertiary-2);
    margin: 16px 0px 24px 0px; }

  .Footer-Contacts-Container {}

  .Footer-LinksContainer {
    border-top: 1px var(--color-tertiary-2) solid;
    padding-top: 24px;
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;
    align-items: start; }

  .ContactLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ContactBlock {
      display: grid;
      grid-template-columns: repeat(3, max-content);
      grid-gap: 24px; }
    .CallUsBlock {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      grid-gap: 24px;
      align-items: center;
      color: var(--color-tertiary-6);
      a {
        color: var(--color-tertiary-6);
        &:hover {
          color: var(--color-primary-8); }
        &:not(:last-child) {
          margin-right: 12px; } } } }

  .Footer-LinkBlock {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    a {
      color: var(--color-tertiary-6); }
    .Title {
      color: var(--color-tertiary-10);
      display: flex;
      align-items: center;
      .Icon {
        color: var(--color-primary-8);
        margin-right: 8px;
        width: 16px; } }
    .Content {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 8px;
      color: var(--color-tertiary-6);
      padding-left: 24px; } } }

.Copyrights {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;

  .Copyrights-Container {
    padding: 24px 0px;
    // border-top: 1px var(--color-tertiary-2) solid
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px; } }

@media screen and ( max-width: 576px ) {
  .Footer {
    .Footer-LinksContainer {
      grid-template-columns: 1fr;
      grid-gap: 24px; }
    hr {
      width: calc(100% - 32px);
      border-top: 1px solid var(--color-tertiary-2);
      margin: 16px 0px 24px 0px; }
    .Footer-Contacts-Container, .ContactLine {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 16px;
      justify-items: center; }
    .ContactLine {
      .ContactBlock {
        grid-template-columns: repeat(2, max-content); }
      .CallUsBlock {
        grid-gap: 16px; } }
    .Copyrights {
      text-align: center; } } }
