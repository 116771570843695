.FeedTile {
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  background-color: #fff;
  border-radius: 4px;
  .Feed-Author {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .UserName {
      font-size: 16px;
      color: var(--color-tertiary-10);
      font-weight: 700; }
    .Date {
      color: var(--color-tertiary-6); } }
  .Feed-Text {
    padding-bottom: 12px;
    height: 192px;
    overflow: hidden; }
  .Button {
    justify-self: start; }
  .Feed-RieltorContainer {
    border-top: 1px var(--color-tertiary-1) solid;
    padding-top: 16px; } }
