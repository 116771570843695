.EduBenefits {
  display: flex;
  justify-content: center; }
.EduBenefits-Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  .EduBenefits-TilesContainer {
    margin-top: 64px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 24px; }
  .EduBenefitTile {
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    align-content: start;

    .EduBenefitIcon {
      width: 100px;
      height: 100px;
      border-radius: 8px;
      background-color: var(--color-tertiary-04);
      display: flex;
      align-items: center;
      justify-content: center; } } }

@media screen and ( max-width: 576px ) {
  .EduBenefits-Container {
    .EduBenefits-TilesContainer {
      margin-top: 48px;
      grid-template-columns: 1fr; }
    .EduBenefitTile {
      justify-items: center;
      .Title, .Description {
        text-align: center; } } } }
