.EstateCarousel {
  display: flex;
  justify-content: center;
  .DefaultContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px; }
  .EstateCarousel-Tiles {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px; }
  .EstateCarousel-Buttons {
    display: flex;
    justify-content: center;
    margin-top: 24px; } }

@media screen and ( max-width: 576px ) {
  .EstateCarousel {
    .EstateCarousel-Tiles {
      grid-template-columns: 1fr; } } }
