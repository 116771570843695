.Rent {
  background-color: var(--color-tertiary-04); }
.Rent-Offer-Title {
  color: #fff; }
.Rent-Offer-Description {
  color: #fff; }
.Rent-EstateCarouselButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .Filters {
    display: flex;
    align-items: center;
    .Filter-Title {
      margin-right: 16px; }
    .FilterButtons {
      .Button {
        &:not(:last-child) {
          margin-right: 16px; } } } } }
