.Header {
  width: 100%;
  height: 350px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .Header-Offer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    .Offer-Text {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 16px; } }
  .Header-Buttons {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 24px; } }

@media screen and ( max-width:  576px) {
  .Header {} }
