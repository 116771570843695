.Counters {
  display: flex;
  justify-content: center;
  .Counters-Container {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 24px; }
  .CounterTile {
    padding-right: 24px;
    border-right: 1px var(--color-tertiary-2) solid;
    &:last-child {
      border-right: 0;
      padding-right: 0; }
    .Counter {
      font-size: 52px;
      font-weight: 700; }
    .Description {
      font-size: 28px;
      font-weight: 700;
      color: var(--color-tertiary-6); } } }

@media screen and ( max-width: 576px ) {
  .Counters {
    .Counters-Container {
      grid-template-columns: 1fr !important;
      .CounterTile {
        padding-right: 0px;
        border-right: 0;
        border-bottom: 1px var(--color-tertiary-2) solid;
        padding-bottom: 24px;
        text-align: center; } } } }
