.SeoText {
  display: flex;
  justify-content: center;
  .SeoText-Container {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 380px 1fr;
    grid-gap: 24px;
    img {
      width: 100%;
      object-fit: cover; } }
  .Text {
    column-count: 2;
    column-gap: 24px; } }

@media screen and ( max-width: 576px ) {
  .SeoText {
    .SeoText-Container {
      grid-template-columns: 1fr; }
    .Text {
      column-count: 1; } } }
