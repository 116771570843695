.WhyWe {
  display: flex;
  justify-content: center; }
.WhyWe-Container {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 24px;
  align-items: start;
  .WhyWeTile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .Box {
      padding: 16px;
      border: 1px var(--color-tertiary-1) solid;
      border-radius: 8px;
      display: grid;
      grid-template-columns: 48px 1fr;
      grid-gap: 12px;
      margin-bottom: 24px;
      background-color: var(--color-tertiary-04); }
    .IconContainer {
      width: 48px;
      height: 48px;
      background: #fff;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 32px; } }
    .BenefitText {
      margin-bottom: 12px; }
    .BenefitDescription {
      color: var(--color-tertiary-4);
      text-align: center; } } }

@media screen and ( max-width: 576px ) {
  .WhyWe-Container {
    grid-template-columns: 1fr; } }
