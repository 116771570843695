.Top {
  width: 100%;
  height: 40px;
  background-color: #fff;
  padding-bottom: 1px;
  border-bottom: 1px var(--color-tertiary-2) solid;
  display: flex;
  justify-content: center;
  .ChooserLink {
    padding: 0px 16px !important;
    &:first-child {
      margin-left: -16px; }
    &:hover {
      border-radius: 0px;
      background-color: var(--color-primary-2); } }
  a {
    display: flex;
    align-items: center; } }
.Top-Container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center; }

.Top-Contact-Buttons {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 32px; }

.Top-Service-Buttons {
  display: grid;
  grid-template-columns: repeat(1, max-content);
  grid-gap: 32px; }

@media screen and ( max-width: 576px ) {
  .Top-Contact-Buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .Top-ContactsEmail {
      display: none; } }
  .Top-Service-Buttons {
    display: none; } }
